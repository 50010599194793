<template>
  <div class="container mt-4">
    <b-row>
      <b-col class="d-flex align-items-center">
        <back-button />
        <h5 class="ml-2 mb-0">
          {{ translations.details.page_title }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button
          data-testid="btn-save"
          variant="primary"
          :disabled="loading"
          @click="onSave">
          {{ translations.details.btn_save }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-card class="border-0">
          <b-row>
            <b-col cols="4">
              <b-form-group
              class="p-0"
              :label="translations.details.assignee">
                <multiselect
                placeholder=""
                :disabled="loading"
                :showPointer="false"
                :allow-empty="true"
                v-model="details.assignee"
                :options="CLAIM_ASSIGNEES"/>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
              class="p-0"
              :label="translations.details.payer_email">
                <div class="d-flex align-items-center">
                  <b-form-tags
                  v-model="formEmailTags.payer_email"
                  tag-variant="info"
                  separator=","
                  no-add-on-enter
                  :tag-validator="tagValidator"
                  :disabled="loading"
                  placeholder=""/>
                  <b-button
                  class="text-muted"
                  variant="link"
                  @click="onCopyToClipboard(formEmailTags.payer_email)">
                    <feather type="copy" aria-label="Copy to clipboard"/>
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
              class="p-0"
              :label="translations.details.csm_email">
                <div class="d-flex align-items-center">
                  <b-form-tags
                  v-model="formEmailTags.csm_email"
                  tag-variant="info"
                  separator=","
                  no-add-on-enter
                  :tag-validator="tagValidator"
                  :disabled="loading"
                  placeholder=""/>
                  <b-button
                  class="text-muted"
                  variant="link"
                  @click="onCopyToClipboard(formEmailTags.csm_email)">
                    <feather type="copy" aria-label="Copy to clipboard"/>
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b>{{ translations.details.account_name }}</b>
              <p>{{ details.account_name || '-' }}</p>
            </b-col>
            <b-col cols="4">
              <b>{{ translations.details.client_name }}</b>
              <p>{{ details.client_name || '-' }}</p>
            </b-col>
            <b-col cols="4">
              <b>{{ translations.details.payer_name }}</b>
              <p>{{ details.payer_name || '-' }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b>{{ translations.details.account_id }}</b>
              <p>{{ details.id || '-' }}</p>
            </b-col>
            <b-col cols="4">
              <b>{{ translations.details.client_id }}</b>
              <b-link v-if="details.client_id" :to="`/onboarding/client/edit/${details.client_id}`" target="_blank">
                <p>{{ details.client_id }}</p>
              </b-link>
              <p v-else>-</p>
            </b-col>
            <b-col cols="4">
              <b>{{ translations.details.payer_id }}</b>
              <p>{{ details.payer_external_id || '-' }}</p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { email } from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';
import { parseResponseError } from '@/http/parsers/parse_response';
import copyToClipboard from '@/scripts/tools/accessibility';
import { CLAIM_ASSIGNEES } from '@/constants/finance';
import translations from '@/translations';
import BackButton from '@/components/BackButton.vue';
import service from '@/services/finance-service';

export default {
  name: 'ClaimAccountsDetails',
  mixins: [ errorHandler ],
  components: {
    BackButton,
    Multiselect,
  },
  data() {
    return {
      CLAIM_ASSIGNEES,
      translations: translations.finance_operations.claim_accounts,
      loading: true,
      details: {},
      formEmailTags: {
        payer_email: [],
        csm_email: [],
      },
    };
  },
  beforeMount() {
    this.fetchDetails();
  },
  methods: {
    async fetchDetails() {
      try {
        this.details = await service.getClaimAccount(this.$route.params.id);
        this.formEmailTags.payer_email = this.details.payer_email.split(',');
        this.formEmailTags.csm_email = this.details.csm_email.split(',');
      } catch (err) {
        this.$noty.error(`${this.translations.errors.fetch_details}: ${parseResponseError(err)}`);
      } finally {
        this.loading = false;
      }
    },
    async onSave() {
      try {
        this.loading = true;

        const payload = {
          assignee: this.details.assignee || '',
          payer_email: this.formEmailTags.payer_email?.join(',') || '',
          csm_email: this.formEmailTags.csm_email?.join(',') || '',
        };

        this.details = await service.updateClaimAccount(this.details.id, payload);
        this.formEmailTags = {
          payer_email: this.details.payer_email.split(','),
          csm_email: this.details.csm_email.split(','),
        };
        this.$noty.success(this.translations.success.save);
      } catch (err) {
        this.$noty.error(`${this.translations.errors.save}: ${parseResponseError(err)}`);
      } finally {
        this.loading = false;
      }
    },
    onCopyToClipboard(data) {
      copyToClipboard(data?.join(',') || '');
    },
    tagValidator(tag) {
      return tag === tag.toLowerCase() && email(tag);
    },
  },
};
</script>
